import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import MobilePromoTemplate from './MobilePromo';

const Networking = () => {
  const { cover, preview, yaml } = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "mobile-promo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      preview: file(relativePath: { eq: "mobile-phones.png" }) {
        childImageSharp {
          fluid(maxWidth: 580) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yaml: allDataYaml(filter: { block: { eq: "mobile-promo" } }) {
        edges {
          node {
            title
            subtitle
            appStoreUrl
            googlePlayUrl
          }
        }
      }
    }
  `);

  return (
    <MobilePromoTemplate
      cover={cover.childImageSharp.fluid}
      preview={preview.childImageSharp.fluid}
      title={yaml.edges[0].node.title}
      subtitle={yaml.edges[0].node.subtitle}
      appStoreUrl={yaml.edges[0].node.appStoreUrl}
      googlePlayUrl={yaml.edges[0].node.googlePlayUrl}
    />
  );
};

export default Networking;
