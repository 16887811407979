import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Tabs.module.scss';

const Tabs = ({
  value,
  options,
  onChange
}) => (
  <div className={style.container}>
    {options.map((option) => {
      const active = option.value === value;

      const className = cn(style.tab, {
        [style.active]: active,
        [style.outline]: !active
      });

      return (
        <button
          key={option.value}
          aria-pressed={String(active)}
          className={className}
          type="button"
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </button>
      );
    })}
  </div>
);

Tabs.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

Tabs.defaultProps = {
  value: null
};

export default Tabs;
