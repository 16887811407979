import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = () => {
  const { allDataYaml, site } = useStaticQuery(
    graphql`
      query {
        allDataYaml(filter: {block: {eq: "heading"}}) {
          edges {
            node {
              title
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const { title } = allDataYaml.edges[0].node;
  const {
    title: metaTitle,
    description,
    author
  } = site.siteMetadata;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'ru'
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={[
        {
          name: 'description',
          content: description
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: description
        }
      ]}
    />
  );
};

export default SEO;
