import React from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { EverysongLogoImage } from '../Images';

import style from './Footer.module.scss';

const Footer = () => (
  <div className={style.container}>
    <Container>
      <Row>
        <Col sm={6}>
          <div className={style.logo}>
            <EverysongLogoImage />
          </div>
        </Col>

        <Col sm={6}>
          <div className={style.info}>
            <p>© 2009 - 2020 Everysong ОГРНИП 318547600168972</p>
            <p>
              Продюсер проекта:
              {' '}
              <a
                href="https://www.instagram.com/Maxong.official/"
                rel="noreferrer noopener"
                target="_blank"
              >
                Maxong
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
