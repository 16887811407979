import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Preloader.module.scss';

const Preloader = ({ className }) => (
  <div className={cn(style.preloader, className)}>
    <div /><div /><div />
  </div>
);

Preloader.propTypes = {
  className: PropTypes.string
};

Preloader.defaultProps = {
  className: ''
};

export default Preloader;
