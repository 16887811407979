import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';
import cn from 'classnames';

import style from './Modal.module.scss';

let modalRoot = null;

function defineModalRoot() {
  const modalRootId = 'modal-root';
  modalRoot = document.getElementById(modalRootId);

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.id = modalRootId;
    document.body.appendChild(modalRoot);
  }
}

export default class Modal extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    classNameInner: PropTypes.string,
    children: PropTypes.node.isRequired,
    onRequestClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: '',
    classNameInner: ''
  };

  innerRef = createRef();

  constructor(...args) {
    super(...args);

    if (!modalRoot) {
      defineModalRoot();
      ReactModal.setAppElement(modalRoot);
    }
  }

  overlay = null;

  componentDidMount() {
    document.body.addEventListener('click', this.onBodyClick, false);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();

    document.documentElement.classList.remove('is-locked');
    document.body.classList.remove('is-locked');
    document.body.removeEventListener('click', this.onBodyClick, false);
  }

  onAfterOpen = () => {
    if (this.overlay) {
      disableBodyScroll(this.overlay);
    }

    document.documentElement.classList.add('is-locked');
    document.body.classList.add('is-locked');
  };

  onBodyClick = ({ target }) => {
    if (!this.innerRef.current || this.innerRef.current.contains(target)) {
      return;
    }

    this.props.onRequestClose();
  };

  render() {
    const {
      className,
      classNameInner,
      children,
      onRequestClose
    } = this.props;

    return (
      <ReactModal
        isOpen
        contentRef={(elem) => { this.overlay = elem; }}
        className={cn(style.modal, className)}
        overlayClassName={style.overlay}
        onRequestClose={onRequestClose}
        onAfterOpen={this.onAfterOpen}
      >
        <div
          ref={this.innerRef}
          className={cn(style.inner, classNameInner)}
        >
          {children}
        </div>

        <button
          className={style.close}
          type="button"
          onClick={onRequestClose}
        />
      </ReactModal>
    );
  }
}
