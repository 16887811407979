import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import style from './LikeButton.module.scss';

const PlayButton = ({
  className,
  count,
  active,
  ...props
}) => (
  <button
    type="button"
    className={cn(style.component, className, {
      [style.active]: active
    })}
    disabled={active}
    {...props}
  >
    <div className={style.icon}>
      <svg
        width="18px"
        height="16px"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6 1C11.08 1 9.8 1.7 9 2.86667C8.2 1.7 6.92 1 5.4 1C3 1 1 2.94444 1 5.27778C1 10.2556 9 15 9 15C9 15 17 10.2556 17 5.27778C17 2.94444 15 1 12.6 1Z" // eslint-disable-line max-len
          fill="url(#red_grad)"
        />

        <linearGradient
          id="red_grad"
          x1="8"
          y1="0"
          x2="8"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF004D" />
          <stop offset="1" stopColor="#FF001F" />
        </linearGradient>
      </svg>
    </div>

    <div className={style.count}>
      {count}
    </div>
  </button>
);

PlayButton.propTypes = {
  count: PropTypes.number,
  active: PropTypes.bool,
  className: PropTypes.string
};

PlayButton.defaultProps = {
  count: 0,
  active: false,
  className: ''
};

export default PlayButton;
