export function safeParse(response) { // eslint-disable-line import/prefer-default-export
  return response.text().then((text) => {
    let json = {};

    try {
      json = JSON.parse(text);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }

    return json;
  });
}

export function parseErrors(json) {
  return Object.keys(json).reduce((errors, key) => ({
    ...errors,
    [key]: Array.isArray(json[key]) ? json[key].join(', ') : json[key]
  }), {});
}
