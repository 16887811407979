import config from '../config';
import { parseErrors, safeParse } from './utils';

function buildUrl(endpoint) {
  return `${config.apiPath}${endpoint}`;
}

const defaultHeaders = {
  'Content-Type': 'application/json'
};

function parseResponse(resolve, reject) {
  return (response) => {
    const { ok, status } = response;

    if (ok || status === 204) {
      resolve(safeParse(response));
      return;
    }

    safeParse(response)
      .then(json => reject(parseErrors(json)));
  };
}

export function register(data) {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('members/'), {
      method: 'POST',
      headers: { ...defaultHeaders },
      body: JSON.stringify(data)
    })
      .then(parseResponse(resolve, reject))
      .catch(error => reject(error));
  });
}

export function getRoles() {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('roles/'), {
      method: 'GET',
      headers: { ...defaultHeaders }
    })
      .then(response => resolve(safeParse(response)))
      .catch(error => reject(error));
  });
}

export function getPromoMembers() {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('promo-members/'), {
      method: 'GET',
      headers: { ...defaultHeaders }
    })
      .then(response => resolve(safeParse(response)))
      .catch(error => reject(error));
  });
}

export function getMembersStats() {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('members/stats/'), {
      method: 'GET',
      headers: { ...defaultHeaders }
    })
      .then(response => resolve(safeParse(response)))
      .catch(error => reject(error));
  });
}

export function setLike(memberId) {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('members/like/'), {
      method: 'POST',
      headers: { ...defaultHeaders },
      body: JSON.stringify({
        member: memberId
      })
    })
      .then(response => resolve(safeParse(response)))
      .catch(error => reject(error));
  });
}

export function setPhoto(memberUID, photo) {
  const formData = new FormData();

  if (photo.filename) {
    formData.append('photo', photo, photo.filename);
  } else {
    formData.append('photo', photo);
  }

  return new Promise((resolve, reject) => {
    fetch(buildUrl(`members/${memberUID}/set_photo/`), {
      method: 'PUT',
      body: formData
    })
      .then(parseResponse(resolve, reject))
      .catch(error => reject(error));
  });
}

export function getTicketTypes() {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('ticket-types/'), {
      method: 'GET',
      headers: { ...defaultHeaders }
    })
      .then(response => resolve(safeParse(response)))
      .catch(error => reject(error));
  });
}

export function getTeams() {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('teams/'), {
      method: 'GET',
      headers: { ...defaultHeaders }
    })
      .then(response => resolve(safeParse(response)))
      .catch(error => reject(error));
  });
}

export function getAvailableTeams() {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('available-teams/'), {
      method: 'GET',
      headers: { ...defaultHeaders }
    })
      .then(response => resolve(safeParse(response)))
      .catch(error => reject(error));
  });
}

export function getAcceptedMembers() {
  return new Promise((resolve, reject) => {
    fetch(buildUrl('accepted-members/'), {
      method: 'GET',
      headers: { ...defaultHeaders }
    })
      .then(response => resolve(safeParse(response)))
      .catch(error => reject(error));
  });
}
