import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  Container, Row, Col, Hidden
} from 'react-grid-system';
import cn from 'classnames';

import { EverysongLogoImage } from '../../components/Images';

import style from './MobilePromo.module.scss';

const MobilePromo = ({
  title,
  subtitle,
  cover,
  preview,
  googlePlayUrl,
  appStoreUrl
}) => (
  <div className={style.container}>
    <Container>
      <Row>
        <Col
          xl={8.75}
          lg={7.75}
          md={12}
          sm={12}
          offset={{
            xl: 3.25,
            lg: 4.25
          }}
        >
          <div className={style.content}>
            <a
              href="https://max-song.ru/"
              className={style.cover}
            >
              <Img
                className={style.coverImage}
                fluid={cover}
                objectFit="cover"
                objectPosition="50% 50%"
                role="presentation"
              />
            </a>

            <Row className={style.main}>
              <Col
                sm={6}
                xs={12}
              >
                <div className={style.logo}>
                  <EverysongLogoImage />
                </div>

                <h3 className={style.title}>
                  {title}
                </h3>

                <p className={style.subtitle}>
                  {subtitle}
                </p>

                <div className={style.appLinks}>
                  {(!!appStoreUrl) && (
                    <a
                      className={cn(style.appLink, style.appStore)}
                      href={appStoreUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Загрузите в App Store"
                    >
                      Загрузите в App Store
                    </a>
                  )}

                  {(!!googlePlayUrl) && (
                    <a
                      className={cn(style.appLink, style.googlePlay)}
                      href={googlePlayUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Доступно в Google Play"
                    >
                      Доступно в Google Play
                    </a>
                  )}
                </div>
              </Col>

              <Hidden xs>
                <Col
                  xs={6}
                  className={style.preview}
                >
                  <Img
                    className={style.previewImage}
                    fluid={preview}
                    alt="Мобильное приложение"
                  />
                </Col>
              </Hidden>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

MobilePromo.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cover: PropTypes.object.isRequired,
  preview: PropTypes.object.isRequired,
  appStoreUrl: PropTypes.string,
  googlePlayUrl: PropTypes.string
};

MobilePromo.defaultProps = {
  appStoreUrl: '',
  googlePlayUrl: ''
};

export default MobilePromo;
