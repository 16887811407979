import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import style from './Button.module.scss';

const ButtonLink = ({
  linkStyle,
  size,
  block,
  className,
  children,
  ...props
}) => (
  <a
    className={cn(style.button, style[linkStyle], style[size], className, {
      [style.block]: block
    })}
    {...props}
  >
    <div className={style.inner}>
      {children}
    </div>
  </a>
);

ButtonLink.propTypes = {
  linkStyle: PropTypes.oneOf([
    'outlineWhite',
    'outlineRed',
    'solidRed'
  ]),
  size: PropTypes.oneOf([
    'md',
    'lg'
  ]),
  block: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

ButtonLink.defaultProps = {
  className: '',
  linkStyle: 'outlineWhite',
  size: 'md',
  block: false,
  children: null
};

export default ButtonLink;
