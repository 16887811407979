import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { Row, Col } from 'react-grid-system';

import TeamMember from './TeamMember';

import style from './TeamsAccordion.module.scss';

const TeamHead = ({
  name,
  image
}) => {
  const imageStyle = {};

  if (image && image.thumbnail) {
    imageStyle.backgroundImage = `url(${image.thumbnail})`;
  }

  return (
    <div className={style.teamHead}>
      <div
        className={style.teamImage}
        style={imageStyle}
      />

      <div className={style.teamName}>
        {name}
      </div>
    </div>
  );
};

TeamHead.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({
    thumbnail: PropTypes.string
  })
};

TeamHead.defaultProps = {
  image: null
};

export const TeamList = ({
  members,
  playing,
  playingMemberId,
  canViewDetail,
  onLikeClick,
  onPlayClick,
  onDetailClick
}) => (
  <div className={style.team}>
    <Row className={style.row}>
      {members.map(member => (
        <Col
          key={member.id}
          md={6}
          xl={4}
          className={style.col}
        >
          <TeamMember
            {...member}
            playing={playing && (playingMemberId === member.id)}
            canViewDetail={canViewDetail}
            onLikeClick={() => onLikeClick(member.id)}
            onPlayClick={() => onPlayClick(member.id)}
            onDetailClick={() => onDetailClick(member.id)}
          />
        </Col>
      ))}
    </Row>
  </div>
);

TeamList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })).isRequired,
  playing: PropTypes.bool.isRequired,
  playingMemberId: PropTypes.number,
  canViewDetail: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onPlayClick: PropTypes.func.isRequired,
  onDetailClick: PropTypes.func.isRequired
};

TeamList.defaultProps = {
  playingMemberId: null
};

const TeamsAccordion = ({
  teams,
  playing,
  playingMemberId,
  canViewDetail,
  onLikeClick,
  onPlayClick,
  onDetailClick
}) => (
  <div className={style.container}>
    {teams.map(({
      id, name, image, members
    }) => (
      <Collapsible
        key={id}
        open
        trigger={<TeamHead name={name} image={image} />}
        easing="ease-in-out"
        className={style.collapsible}
        openedClassName={style.collapsible}
        triggerClassName={style.trigger}
        triggerOpenedClassName={style.triggerOpened}
        contentInnerClassName={style.contentInner}
      >
        <TeamList
          members={members}
          playing={playing}
          playingMemberId={playingMemberId}
          canViewDetail={canViewDetail}
          onLikeClick={onLikeClick}
          onPlayClick={onPlayClick}
          onDetailClick={onDetailClick}
        />
      </Collapsible>
    ))}
  </div>
);

TeamsAccordion.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    name: PropTypes.string.isRequired,
    members: PropTypes.array.isRequired
  })).isRequired,
  playing: PropTypes.bool.isRequired,
  playingMemberId: PropTypes.number,
  canViewDetail: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onPlayClick: PropTypes.func.isRequired,
  onDetailClick: PropTypes.func.isRequired
};

TeamsAccordion.defaultProps = {
  playingMemberId: null
};

export default TeamsAccordion;
