export default {
  apiProtocol: process.env.GATSBY_API_PROTOCOL,
  apiHost: process.env.GATSBY_API_HOST,
  apiPath: process.env.GATSBY_API_PATH,
  appProtocol: process.env.GATSBY_APP_PROTOCOL,
  appDomain: process.env.GATSBY_APP_DOMAIN,
  googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  vkontakteAppId: process.env.GATSBY_VKONTAKTE_APP_ID,
  facebookAppId: process.env.GATSBY_FACEBOOK_APP_ID,
  instagramAppId: process.env.GATSBY_INSTAGRAM_APP_ID,
  googleAppId: process.env.GATSBY_GOOGLE_APP_ID
};
