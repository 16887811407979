import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import style from './Button.module.scss';

const Button = ({
  type,
  buttonStyle,
  size,
  block,
  className,
  children,
  ...props
}) => (
  <button // eslint-disable-line react/button-has-type
    type={type}
    className={cn(style.button, style[buttonStyle], style[size], className, {
      [style.block]: block
    })}
    {...props}
  >
    <div className={style.inner}>
      {children}
    </div>
  </button>
);

Button.propTypes = {
  type: PropTypes.oneOf([
    'button',
    'reset',
    'submit'
  ]),
  buttonStyle: PropTypes.oneOf([
    'outlineWhite',
    'outlineRed',
    'solidRed'
  ]),
  size: PropTypes.oneOf([
    'md',
    'lg'
  ]),
  block: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

Button.defaultProps = {
  className: '',
  buttonStyle: 'outlineWhite',
  size: 'md',
  block: false,
  children: null,
  type: 'button'
};

export default Button;
