import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';

import Header from '../../components/Header';
import { PromoImage } from '../../components/Images';

import style from './HeadingTeams.module.scss';

const HeadingTeams = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataYaml(filter: {block: {eq: "heading-teams"}}) {
        edges {
          node {
            date,
            title,
            vkUrl,
            igUrl
          }
        }
      }
    }
  `);

  const {
    date,
    title,
    vkUrl,
    igUrl
  } = data.allDataYaml.edges[0].node;

  const titleText = title.replace(/[^\sA-zА-я]/gi, '');
  const titleNumber = title.replace(/[\sA-zА-я]/gi, '');

  return (
    <div className={style.container}>
      <div className={style.header}>
        <Header date={date} />
      </div>

      <Container>
        <div className={style.image}>
          <PromoImage />
        </div>

        <Row align="stretch">
          <Col
            xs={3}
            className={style.colSocial}
          >
            <Row nogutter align="end" style={{ height: '100%' }}>
              <div className={style.socials}>
                <div>
                  <a
                    href={vkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={style.socialLink}
                  >
                    VK
                  </a>
                </div>

                <div>
                  <a
                    href={igUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={style.socialLink}
                  >
                    IG
                  </a>
                </div>
              </div>
            </Row>
          </Col>

          <Col
            xs={12}
            sm={9}
            className={style.colTitle}
          >
            <h1 className={style.title}>
              {titleText}

              <span className={style.outlineText}>
                {titleNumber}
              </span>
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeadingTeams;
