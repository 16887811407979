import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const PromoImage = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "promo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.image.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        role="presentation"
        style={{ width: '100%', height: '100%' }}
      />
    )}
  />
);

export default PromoImage;
