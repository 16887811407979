import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import Sound from 'react-sound';

import TeamsAccordion from '../../components/TeamsAccordion';
import { TeamList } from '../../components/TeamsAccordion/TeamsAccordion';
import RolesFilter from '../../components/RolesFilter';
import Tabs from '../../components/Tabs';
import TeamMemberDetailModal from '../../components/TeamMemberDetailModal';
import Preloader from '../../components/Preloader';
import { isNotEmptyArray } from '../../utils';

import style from './Teams.module.scss';

const Teams = ({
  roles,
  teams,
  playing,
  playingMember,
  rolesActive,
  sorting,
  sortingOptions,
  specialization,
  detailMember,
  fetching,
  canViewDetail,
  onLikeClick,
  onPlayClick,
  onDetailClick,
  onRoleChange,
  onSortingChange,
  onSpecializationChange,
  onFinishedPlaying
}) => (
  <div className={style.container}>
    <Sound
      url={(playing && playingMember) ? playingMember.audioFile : ''}
      playStatus={playing ? Sound.status.PLAYING : Sound.status.PAUSED}
      onFinishedPlaying={onFinishedPlaying}
    />

    <Container>
      <Row>
        <Col xl={3} lg={4} md={12} sm={12}>
          <div className={style.filterGroup}>
            <h3 className={style.filterTitle}>
              Отображать
            </h3>

            <Tabs
              value={sorting}
              options={sortingOptions}
              onChange={onSortingChange}
            />
          </div>

          <div className={style.filterGroup}>
            <h3 className={style.filterTitle}>
              Специальность
            </h3>

            <RolesFilter
              roles={roles}
              specialization={specialization}
              rolesActive={rolesActive}
              onRoleChange={onRoleChange}
              onSpecializationChange={onSpecializationChange}
            />
          </div>
        </Col>

        <Col
          xl={8.75}
          lg={7.75}
          md={12}
          sm={12}
          offset={{ lg: 0.25, md: 0 }}
        >
          {(fetching) && (
            <div className={style.preloader}>
              <Preloader />
            </div>
          )}

          {(!fetching && !isNotEmptyArray(teams)) && (
            <div className={style.empty}>
              По вашему запросу <br />нет результатов
            </div>
          )}

          {(!fetching && isNotEmptyArray(teams)) && (
            <Fragment>
              {(sorting === 'teams') && (
                <TeamsAccordion
                  teams={teams}
                  playing={playing}
                  playingMemberId={playingMember ? playingMember.id : null}
                  canViewDetail={canViewDetail}
                  onLikeClick={onLikeClick}
                  onPlayClick={onPlayClick}
                  onDetailClick={onDetailClick}
                />
              )}

              {(sorting !== 'teams') && (
                <TeamList
                  members={teams}
                  playing={playing}
                  playingMemberId={playingMember ? playingMember.id : null}
                  canViewDetail={canViewDetail}
                  onLikeClick={onLikeClick}
                  onPlayClick={onPlayClick}
                  onDetailClick={onDetailClick}
                />
              )}
            </Fragment>
          )}
        </Col>

        {!!detailMember && (
          <TeamMemberDetailModal
            member={detailMember}
            onClose={() => onDetailClick(null)}
          />
        )}
      </Row>
    </Container>
  </div>
);

Teams.propTypes = {
  roles: PropTypes.array.isRequired,
  rolesActive: PropTypes.array.isRequired,
  specialization: PropTypes.bool.isRequired,
  teams: PropTypes.array.isRequired,
  sorting: PropTypes.string.isRequired,
  sortingOptions: PropTypes.array.isRequired,
  detailMember: PropTypes.object,
  playing: PropTypes.bool.isRequired,
  playingMember: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  canViewDetail: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onPlayClick: PropTypes.func.isRequired,
  onDetailClick: PropTypes.func.isRequired,
  onRoleChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  onSpecializationChange: PropTypes.func.isRequired,
  onFinishedPlaying: PropTypes.func.isRequired
};

Teams.defaultProps = {
  detailMember: null,
  playingMember: null
};

export default Teams;
