export function nonEmptyCheck(value) {
  return (value != null) && !!value.toString().trim();
}

export function nonEmptyArrayCheck(value) {
  return value && Array.isArray(value) && value.length > 0;
}

export function emailCheck(value) {
  const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/; // eslint-disable-line max-len
  return re.test(value);
}

export function cyrillicCheck(value) {
  const re = /[^а-яА-ЯёЁ ]+/;
  return !re.test(value);
}

export function urlCheck(value) {
  const re = /((?:https?:\/\/|www\.)?(?:[-a-z0-9]+\.)+[-a-z0-9]+.*)/; // eslint-disable-line max-len
  return re.test(value);
}
