import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';

import Checkbox from '../Checkbox';

import style from './RolesFilter.module.scss';

const RolesFilter = ({
  roles,
  rolesActive,
  specialization,
  onRoleChange,
  onSpecializationChange
}) => (
  <div className={style.container}>
    <Row className={style.list}>
      {roles.map(role => (
        <Col
          lg={12}
          md={6}
          key={role.id}
          className={style.item}
        >
          <Checkbox
            name={`role-${role.id}`}
            className={style.checkbox}
            checked={rolesActive.indexOf(role.id) > -1}
            onChange={() => onRoleChange(role.id)}
          >
            {role.name}
          </Checkbox>
        </Col>
      ))}
    </Row>

    <div className={style.specialization}>
      <Checkbox
        name="role-special"
        className={style.checkbox}
        checked={specialization}
        onChange={onSpecializationChange}
      >
        Показывать только тех, у&nbsp;кого выбранная специальность&nbsp;&mdash; основная
      </Checkbox>
    </div>
  </div>
);

RolesFilter.propTypes = {
  roles: PropTypes.array.isRequired,
  rolesActive: PropTypes.array.isRequired,
  specialization: PropTypes.bool.isRequired,
  onRoleChange: PropTypes.func.isRequired,
  onSpecializationChange: PropTypes.func.isRequired
};

export default RolesFilter;
