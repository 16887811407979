import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import { urlCheck } from '../../utils/validate';

import style from './TeamMemberDetailModal.module.scss';

const fixProtocol = url => (url.startsWith('http') ? url : `https://${url}`);

const renderNode = (text) => {
  const words = text.split(/\s+/);

  return words.map((word) => {
    if (!urlCheck(word)) {
      return `${word} `;
    }

    return (
      <a
        key={word}
        className={style.url}
        href={fixProtocol(word)}
        target="_blank"
        rel="noreferrer noopener"
      >
        {word}
      </a>
    );
  });
};

function urlify(text) {
  const lines = text.split(/\n/);

  return lines.map(line => (
    <p
      key={line}
      className={style.line}
    >
      {renderNode(line)}
    </p>
  ));
}

const TeamMemberDetailModal = ({
  member: {
    firstName,
    lastName,
    bestWorkRole,
    about,
    social,
    instagramProfileUrl
  },
  onClose
}) => (
  <Modal
    className={style.component}
    classNameInner={style.componentInner}
    onRequestClose={onClose}
  >
    <div className={style.content}>
      <div className={style.title}>
        {firstName} {lastName}
      </div>

      {!!bestWorkRole && (
        <Fragment>
          <div className={style.subtitle}>
            Роль в этой песне:
          </div>

          <div className={style.text}>
            {bestWorkRole}
          </div>
        </Fragment>
      )}

      {!!about && (
        <Fragment>
          <div className={style.subtitle}>
            О себе:
          </div>

          <div className={style.text}>
            {about}
          </div>
        </Fragment>
      )}

      {(!!social || !!instagramProfileUrl) && (
        <Fragment>
          <div className={style.subtitle}>
            Соцсети
          </div>

          <div className={style.text}>
            {urlify(social)}
          </div>
        </Fragment>
      )}

      {!!instagramProfileUrl && (
        <Fragment>
          <div className={style.subtitle}>
            Instagram
          </div>

          <div className={style.text}>
            {renderNode(instagramProfileUrl)}
          </div>
        </Fragment>
      )}
    </div>
  </Modal>
);

TeamMemberDetailModal.propTypes = {
  member: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    bestWorkRole: PropTypes.string,
    about: PropTypes.string,
    social: PropTypes.string,
    instagramProfileUrl: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func
};

TeamMemberDetailModal.defaultProps = {
  onClose: () => {}
};

export default TeamMemberDetailModal;
