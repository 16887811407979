import React from 'react';
import {
  Row, Col, Container, Hidden
} from 'react-grid-system';
import PropTypes from 'prop-types';

import { EverysongLogoImage } from '../Images';
import { ButtonLink } from '../Button';

import style from './Header.module.scss';

const Header = ({
  date,
  isMainPage
}) => (
  <div className={style.container}>
    <Container className={style.inner}>
      <Row align="center">
        <Col sm={!isMainPage ? 2 : 4} xs={6}>
          <a
            href="https://everysong.pro/"
            className={style.logo}
          >
            <EverysongLogoImage />
          </a>
        </Col>

        <Hidden xs>
          <Col sm={!isMainPage ? 6 : 8}>
            <div className={style.date}>
              {date}
            </div>
          </Col>
        </Hidden>

        {!isMainPage && (
          <Col sm={4} xs={6}>
            <Row justify="end" nogutter>
              <div className={style.button}>
                <ButtonLink
                  href="/"
                  linkStyle="outlineWhite"
                >
                  На главную
                </ButtonLink>
              </div>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  </div>
);

Header.propTypes = {
  isMainPage: PropTypes.bool,
  date: PropTypes.string.isRequired
};

Header.defaultProps = {
  isMainPage: false
};

export default Header;
