import scrollDoc from 'scroll-doc';
import scroll from 'scroll';

import storageFactory from './storage';

export const noop = () => {};

export const localStorage = storageFactory('localStorage');

export const isNotEmptyArray = obj => Array.isArray(obj) && obj.length > 0;

export const createArray = length => Array.from(new Array(length).keys());

export const scrollToElement = (elementId, duration = 500) => {
  const domElement = document.getElementById(elementId);

  if (domElement) {
    const top = domElement.offsetTop;
    const scrollElement = scrollDoc();
    scroll.top(scrollElement, top, { duration });
  }
};

export const getPlurals = (number, one, two, five) => {
  let count = Math.abs(number);

  count %= 100;

  if (count >= 5 && count <= 20) {
    return five;
  }

  count %= 10;

  if (count === 1) {
    return one;
  }

  if (count >= 2 && count <= 4) {
    return two;
  }

  return five;
};
