import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Checkbox.module.scss';

const Checkbox = ({
  className,
  children,
  checked,
  name,
  ...props
}) => (
  <label
    htmlFor={`checkbox-${name}`}
    className={cn(style.label, className)}
  >
    <input
      id={`checkbox-${name}`}
      name={name}
      className={style.input}
      type="checkbox"
      checked={checked}
      {...props}
    />

    <span className={style.checkmark} />

    {children}
  </label>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired
};

Checkbox.defaultProps = {
  className: '',
  children: null
};

export default Checkbox;
