import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TeamsTemplate from './Teams';
import {
  getAcceptedMembers,
  getRoles,
  getTeams,
  setLike
} from '../../api';
import { isNotEmptyArray } from '../../utils';

const unsetKey = 'unset';

const sortingOptions = [
  { label: 'По рейтингу', value: 'rating' },
  { label: 'По командам', value: 'teams' }
];

export default class Teams extends PureComponent {
  static propTypes = {
    canViewDetail: PropTypes.bool
  };

  static defaultProps = {
    canViewDetail: false
  };

  state = {
    teams: [],
    members: [],
    roles: [],
    rolesActive: [],
    sorting: sortingOptions[0].value,
    specialization: false,
    detailMember: null,
    playing: false,
    playingMemberId: null,
    fetching: false
  };

  componentDidMount() {
    this.setState({ fetching: true });

    const promises = Promise.all([
      getTeams(),
      getAcceptedMembers(),
      getRoles()
    ]);

    promises.then(([teams, members, roles]) => this.setState({
      teams,
      members,
      roles,
      fetching: false
    }));
  }

  getItems = () => {
    const items = this.filterItems();
    return this.groupItems(items);
  };

  filterItems = () => {
    const {
      members,
      rolesActive,
      specialization
    } = this.state;

    if (!isNotEmptyArray(rolesActive)) {
      return members;
    }

    if (specialization) {
      return members.filter(member => rolesActive.includes(member.specialization));
    }

    return members.filter(({ roles }) => roles.find(({ id }) => rolesActive.includes(id)));
  };

  groupItems = (members) => {
    const { teams, sorting } = this.state;
    const memebersEtended = members.map((member) => {
      let team = null;

      if (member.team) {
        team = teams.find(({ id }) => id === member.team) || null;
      }

      return {
        ...member,
        team
      };
    });
    let groups = [];

    if (sorting !== 'teams') {
      groups = [...memebersEtended];
      groups.sort((p, n) => n.likesCount - p.likesCount);
      return groups;
    }

    const membersByTeam = memebersEtended.reduce((acc, member) => {
      const key = member.team !== null ? member.team.id : unsetKey;

      return {
        ...acc,
        [key]: [
          ...(acc[key] || []),
          member
        ]
      };
    }, {});

    groups = teams.reduce((acc, team) => {
      if (!membersByTeam[team.id]) {
        return acc;
      }

      const group = {
        ...team,
        members: membersByTeam[team.id]
      };

      return [...acc, group];
    }, []);

    if (membersByTeam[unsetKey]) {
      groups.push({
        id: unsetKey,
        name: 'Без команды',
        members: membersByTeam[unsetKey]
      });
    }

    return groups;
  };

  getLikesFromLS = () => {
    let likes = [];

    try {
      likes = JSON.parse(localStorage.getItem('likes')) || [];
    } catch (e) {
      // ignore
    }

    return likes;
  };

  setLikesToLS = (likes) => {
    localStorage.setItem('likes', JSON.stringify(likes));
  };

  onPlayClick = memberId => this.setState((state) => {
    const member = state.members.find(m => m.id === memberId);

    if (!member) {
      return null;
    }

    if (member.id === state.playingMemberId) {
      return { playing: !state.playing };
    }

    return {
      playing: true,
      playingMemberId: member.id
    };
  });

  onFinishedPlaying = () => this.setState({
    playing: false
  });

  getMemberById = (memberId) => {
    const { members } = this.state;
    return members.find(({ id }) => id === memberId);
  };

  onLikeClick = memberId => this.setState((state) => {
    const likes = this.getLikesFromLS();
    const likesIdx = likes.indexOf(memberId);

    if (likesIdx !== -1) {
      return null;
    }

    const members = [ ...state.members ];
    const memberIdx = members.findIndex(m => m.id === memberId);

    likes.push(memberId);
    members.splice(memberIdx, 1, {
      ...members[memberIdx],
      liked: true,
      likesCount: members[memberIdx].likesCount + 1
    });

    this.setLikesToLS(likes);
    setLike(memberId);

    return {
      members
    };
  });

  onDetailClick = memberId => this.setState(({ members }) => ({
    detailMember: members.find(member => member.id === memberId) || null
  }));

  onRoleChange = role => this.setState((state) => {
    const rolesActive = [...state.rolesActive];
    const index = rolesActive.indexOf(role);

    if (index > -1) {
      rolesActive.splice(index, 1);
    } else {
      rolesActive.push(role);
    }

    return { rolesActive };
  });

  onSpecializationChange = () => this.setState(state => ({
    specialization: !state.specialization
  }));

  onSortingChange = sorting => this.setState({ sorting });

  render() {
    const {
      sorting,
      roles,
      rolesActive,
      specialization,
      detailMember,
      playing,
      playingMemberId,
      fetching
    } = this.state;

    const teams = this.getItems();
    const playingMember = this.getMemberById(playingMemberId);

    return (
      <TeamsTemplate
        fetching={fetching}
        rolesActive={rolesActive}
        roles={roles}
        specialization={specialization}
        teams={teams}
        sorting={sorting}
        sortingOptions={sortingOptions}
        detailMember={detailMember}
        playing={playing}
        playingMember={playingMember}
        canViewDetail={this.props.canViewDetail}
        onFinishedPlaying={this.onFinishedPlaying}
        onSortingChange={this.onSortingChange}
        onRoleChange={this.onRoleChange}
        onSpecializationChange={this.onSpecializationChange}
        onLikeClick={this.onLikeClick}
        onPlayClick={this.onPlayClick}
        onDetailClick={this.onDetailClick}
      />
    );
  }
}
