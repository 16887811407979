import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { isNotEmptyArray } from '../../utils';
import LikeButton from '../LikeButton';

import style from './TeamMember.module.scss';

const Member = ({
  photo,
  firstName,
  lastName,
  team,
  online,
  offline,
  liked,
  likesCount,
  roles,
  specialization,
  teamCurator,
  playing,
  canViewDetail,
  onPlayClick,
  onLikeClick,
  onDetailClick
}) => (
  <div
    className={cn(style.member, {
      [style.curator]: teamCurator
    })}
  >
    <div className={style.topPanel}>
      <div className={style.imageContainer}>
        <div
          className={style.image}
          style={photo ? { backgroundImage: `url(${photo.thumbnail})` } : null}
        />

        <button
          type="button"
          className={cn(style.controlBtn, {
            [style.play]: !playing,
            [style.pause]: playing
          })}
          onClick={onPlayClick}
        />
      </div>

      <div className={style.text}>
        <div className={style.name}>
          <div>{firstName}</div>
          <div>{lastName}</div>
        </div>

        <div className={style.meta}>
          {!!team && (
            <span className={style.teamLabel}>
              {team.name}
            </span>
          )}

          {online && (
            <span className={style.onlineLabel}>
              Онлайн
            </span>
          )}

          {offline && (
            <span className={style.onlineLabel}>
              Лично
            </span>
          )}
        </div>
      </div>

      <div className={style.likes}>
        <LikeButton
          count={likesCount}
          active={liked}
          onClick={onLikeClick}
        />
      </div>
    </div>

    {isNotEmptyArray(roles) && (
      <ul className={style.rolesList}>
        {roles.map(({ id, name }) => (
          <li
            key={id}
            className={style.rolesListItem}
          >
            <div
              className={cn(style.role, {
                [style.special]: specialization === id
              })}
            >
              {name}
            </div>
          </li>
        ))}
      </ul>
    )}

    {(canViewDetail || teamCurator) && (
      <div className={style.bottom}>
        {canViewDetail && (
          <button
            type="button"
            className={style.detailBtn}
            onClick={onDetailClick}
          >
            Подробности +
          </button>
        )}

        {teamCurator && (
          <div className={style.curatorLabel}>
            Куратор
          </div>
        )}
      </div>
    )}
  </div>
);

Member.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  photo: PropTypes.shape({
    thumbnail: PropTypes.string.isRequired
  }),
  liked: PropTypes.bool.isRequired,
  likesCount: PropTypes.number.isRequired,
  team: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  online: PropTypes.bool,
  offline: PropTypes.bool,
  specialization: PropTypes.number,
  teamCurator: PropTypes.bool.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  playing: PropTypes.bool.isRequired,
  canViewDetail: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onPlayClick: PropTypes.func.isRequired,
  onDetailClick: PropTypes.func.isRequired
};

Member.defaultProps = {
  photo: null,
  team: null,
  online: false,
  offline: false,
  specialization: null,
  roles: null
};

export default Member;
